
  import { defineComponent, onMounted, ref } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import useProducts from "@/composables/useProducts";
  import usePriceList from "@/composables/usePriceList";
  import useProductPriceList from "@/composables/useProductPriceList";
  import { PriceListFilter, ProductUnitFilter } from "@/models";
  import { hideModal, showModal } from "@/core/helpers/dom";
  // import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import {
    IGetProductForViewDto,
    ProductDto,
    ProductPriceDto,
  } from "@/shared/service-proxies/service-proxies";
  import { IGetProductPriceForViewDto } from "@/shared/service-proxies/generated-proxies";
  import { FileUploadDTo } from "@/models";
  import { useStore } from "vuex";
  import { formatCurrency, formatPrice } from "@/core/services/HelperServices";
  import Swal from "sweetalert2/dist/sweetalert2.min.js";
  import { Actions } from "@/store/enums/StoreEnums";
  export default defineComponent({
    components: {},
    setup() {
      const loaded = ref(false);
      const loading = ref(false);
      const {
        product,
        getProduct,
        productUnits,
        getProductUnits,
        uploadImage,
      } = useProducts();
      const {
        getProductPriceList,
        productPriceLists,
        edit,
        create,
        deleteProductList,
      } = useProductPriceList();
      const route = useRoute();
      const router = useRouter();
      const breadcrumbs = {
        title: "Product Details",
        breadcrumbs: ["Product", "Details"],
      };
      const openEditModalRef = ref<null | HTMLElement>(null);

      const selectedProductUnit = ref("");
      const productPriceEditData = ref(
        {} as unknown as IGetProductPriceForViewDto
      );
      const price = ref("0");
      const store = useStore();
      const config = store.getters.getConfigurations;
      const permissions = config.result.auth.grantedPermissions;

      const defaultFilter = ref({
        sorting: null,
        skipCount: 0,
        maxResultCount: 1000,
      } as unknown as PriceListFilter);
      const { priceLists, getPriceLists } = usePriceList();

      const imgUploaded = ref(false);
      const fileUploadImg = ref("");
      const fileUpload = ref();
      const fileUploadImage = ref({
        name: "",
        size: "",
        type: "",
      } as unknown as FileUploadDTo);

      let modelValue = ref<IGetProductForViewDto>({
        product: {
          productCode: "",
          productName: "",
          unitPrice: 0,
          imageURL: "",
          rcS8Code: "",
          productCategoryId: "",
          productBaseUnitId: "",
          isActive: false,
          id: "",
        } as unknown as ProductDto,
        productPrices: undefined,
        productCategoryName: "",
        productUnitName: "",
      });

      onMounted(async () => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
        // setCurrentPageBreadcrumbs("Products Details", ["Product", "Details"]);
        await getPriceListsData();
        await getUnits();
        await getProduct(route.params.id as string);

        await getProductPriceList(route.params.id as string);

        productPriceEditData.value =
          productPriceLists.value[0].productPrices![0];
        modelValue.value = product.value;

        loaded.value = true;
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      });

      const openEditModal = async (productList) => {
        productPriceEditData.value = productList;
        showModal("openEditModal");
      };

      const editProductPrice = async () => {
        loading.value = true;
        await edit(
          productPriceEditData.value.productPrice as unknown as ProductPriceDto,
          undefined
        );
        const error = store.getters.getErrors;
        loading.value = false;
        hideModal(openEditModalRef.value);
        if (error) {
          Swal.fire({
            text: error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        } else {
          Swal.fire({
            text: "Price list updated successfully",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Okay!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
            },
          });
        }
      };

      const deleteProductPriceList = async (id: string) => {
        Swal.fire({
          title: "Are you sure?",
          text: "",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then(async (willDelete: boolean) => {
          if (willDelete) {
            store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
            await deleteProductList(id);
            store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
            const error = store.getters.getErrors;
            if (error) {
              Swal.fire({
                text: error,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Try again!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            } else {
              await getProductPriceList(route.params.id as string);
              Swal.fire({
                text: "Price list deleted successfully",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Okay!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-success",
                },
              });
            }
          }
        });
      };

      const addProductPrice = async (productPriceListId) => {
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
        const prodPrice = {
          productId: route.params.id,
          productUnitId: selectedProductUnit.value,
          unitPrice: price.value,
          priceListId: productPriceListId,
        };
        await create(prodPrice as unknown as ProductPriceDto, undefined);
        price.value = "0";
        const error = store.getters.getErrors;

        if (error) {
          Swal.fire({
            text: error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        } else {
          await getProductPriceList(route.params.id as string);
          Swal.fire({
            text: "Price list created successfully",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Okay!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
            },
          });
        }
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      };

      const getUnits = async () => {
        await getProductUnits(
          defaultFilter.value as unknown as ProductUnitFilter
        );
      };

      const getPriceListsData = async () => {
        await getPriceLists(defaultFilter.value as unknown as PriceListFilter);
      };

      const selectForFile = (event) => {
        imgUploaded.value = true;
        var file = event.target.files[0];
        fileUpload.value = file;
        fileUploadImage.value = file;
        fileUploadImg.value = URL.createObjectURL(file);
      };

      const uploadFile = async () => {
        const formData: FormData = new FormData();
        formData.append("file", fileUpload.value, fileUpload.value.name);
        store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");
        formData.append("productId", route.params.id as string);
        await uploadImage(formData);
        window.location.reload();
      };

      const clearSelection = () => {
        imgUploaded.value = false;
        fileUploadImg.value = "";
        fileUploadImage.value = {} as unknown as FileUploadDTo;
      };

      return {
        formatPrice,
        loading,
        loaded,
        openEditModalRef,
        editProductPrice,
        openEditModal,
        router,
        modelValue,
        imgUploaded,
        fileUploadImg,
        fileUploadImage,
        uploadFile,
        selectForFile,
        clearSelection,
        breadcrumbs,
        permissions,
        formatCurrency,
        priceLists,
        productUnits,
        productPriceLists,
        addProductPrice,
        selectedProductUnit,
        productPriceEditData,
        price,
        deleteProductPriceList,
      };
    },
  });
