import { ref, Ref } from "vue";
import { axiosClient, APP_BASE_URL } from "@/core/services/AxiosClient";
import { CancelToken } from "axios";
import { useToast } from "vue-toast-notification";
const toast = useToast();

import {
  ProductPriceDto,
  ProductPriceServiceProxy,
  IGetPriceListProductPriceForViewDto,
} from "@/shared/service-proxies/generated-proxies";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";

interface UsableModel {
  productPriceLists: Ref<Array<IGetPriceListProductPriceForViewDto>>;
  getProductPriceList: (id: string) => void;
  create: (
    product: ProductPriceDto,
    cancelToken: undefined | CancelToken
  ) => void;
  edit: (
    product: ProductPriceDto,
    cancelToken: undefined | CancelToken
  ) => void;
  deleteProductList: (id: string) => Promise<boolean>;
}

const useProductPriceList = (): UsableModel => {
  const ProductPriceListClient = new ProductPriceServiceProxy(
    APP_BASE_URL,
    axiosClient
  );

  const productPriceLists: Ref<Array<IGetPriceListProductPriceForViewDto>> =
    ref(null as unknown as Array<IGetPriceListProductPriceForViewDto>);

  const store = useStore();

  const getProductPriceList = async (id: string) => {
    await ProductPriceListClient.getAllForProduct(id)
      .then((data) => {
        productPriceLists.value = data;
      })
      .catch((error) => {
        toast.warning(error.message);
        store.commit(Mutations.SET_ERROR, error);
      });
  };

  const create = async (
    inputData: ProductPriceDto,
    cancelToken: CancelToken | undefined
  ) => {
    await ProductPriceListClient.create(inputData, cancelToken).catch(
      (error) => {
        store.commit(Mutations.SET_ERROR, error);
      }
    );
  };

  const edit = async (
    inputData: ProductPriceDto,
    cancelToken: CancelToken | undefined
  ) => {
    await ProductPriceListClient.edit(inputData, cancelToken).catch((error) => {
      store.commit(Mutations.SET_ERROR, error);
    });
  };

  const deleteProductList = async (id: string): Promise<boolean> => {
    const resp = await ProductPriceListClient.delete(id)
      .then(() => true)
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error.response.error);
        return false;
      });
    return resp;
  };

  return {
    getProductPriceList,
    productPriceLists,
    create,
    edit,
    deleteProductList,
  };
};

export default useProductPriceList;
